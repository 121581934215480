<template>
    <div class="ui translucent suimodal background-transparent" :class="{ small: !checkMobile, overlay: checkMobile, fullscreen: checkMobile }">
        <i class="close icon" />
        <div class="header">
            {{$t('profile.editar_informacoes_pessoais')}}
        </div>
        <div class="content">
            <div class="ui top attached tabular three item menu">
                <a class="item" :class="{active:tab==='personal'}" @click.prevent="tab='personal'">{{$t('profile.dados_pessoais')}}</a>
                <a class="item" :class="{active:tab==='password'}" @click.prevent="tab='password'">{{$t('profile.senha')}}</a>
                <a class="item" :class="{active:tab==='account'}" @click.prevent="tab='account'">{{$t('profile.conta')}}</a>
            </div>
            <div v-show="tab==='personal'" class="ui bottom attached tab segment" :class="{active:tab==='personal'}">
                <form class="ui form">
                    <div class="fields">
                        <div class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input v-model="user.name" type="text" name="name" :placeholder="$t('signup.nomeCompleto')">
                            <p class="small">{{ $t('signup.ate255Caracteres') }}</p>
                        </div>
                        <div class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input v-model="user.nickname" v-mask="'NNNNNNNNNNNNN'" type="text" name="nickname" :placeholder="$t('signup.apelido')">
                            <p class="small">{{ $t('signup.de5a13Caracteres') }}</p>
                        </div>
                        <div class="field sixteen wide">
                            <input v-model="user.email" type="email" name="email" :placeholder="$t('signup.meuEmail')">
                        </div>
                        <div v-if="has('phone')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input v-model="user.phone" type="text" name="phone" :placeholder="$t('signup.phone')" @keyup="mask_phone">
                        </div>
                        <div v-if="has('cpf')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input v-model="user.cpf" type="text" name="cpf" :placeholder="$t('signup.cpf')" @keyup="mask_cpf">
                        </div>
                        <div v-if="has('enrollment')" class="field wide"
                             :class="{ eight: !checkMobile, sixteen: checkMobile }"
                        >
                            <input id="enrollment" ref="enrollment" v-model="user.enrollment" class="text"
                                   :placeholder="$t('signup.enrollmentOrCPF')" name="enrollment" type="text"
                            >
                        </div>
                        <div v-if="has('birth')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <div id="birth_date" class="ui calendar">
                                <div class="ui fluid input left icon">
                                    <i class="calendar icon" />
                                    <input id="birth_date" ref="birth_date" v-model="user.birth" v-mask="'##/##/####'"
                                           type="text" name="birth_date" :placeholder="$t('signup.dataDeNascimento')"
                                    >
                                </div>
                            </div>
                            <p v-if="only_major" class="small">
                                {{ $t('signup.only_major')}}
                            </p>
                        </div>
                        <div v-if="has('company')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <input type="text" v-model="user.company" name="company" :placeholder="$t('signup.company')">
                        </div>
                        <div v-if="has('state')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select v-model="user.state" name="state" class="ui fluid suidropdown" @change="cityHandler">
                                <option :value="null">
                                    {{ $t('signup.state') }}
                                </option>
                                <option v-for="state in states" :key="state.id" :value="state.value"
                                        :data-value="state.id"
                                >
                                    {{ state.value }}
                                </option>
                            </select>
                        </div>
                        <div v-if="has('state')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select v-model="user.city" name="city" class="ui fluid suidropdown">
                                <option :value="null">
                                    {{ $t('signup.city') }}
                                </option>
                                <option v-for="(item, index) in cities" :key="index" :value="item.name">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                        <div v-if="has('region')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select id="region" ref="region" v-model="user.region" class="ui fluid suidropdown"
                                    name="region"
                            >
                                <option :value="null">
                                    {{ $t('signup.region') }}
                                </option>
                                <option v-for="region in regions" :key="region.id" :value="region.id">
                                    {{ region.region_name }}
                                </option>
                            </select>
                        </div>
                        <div v-if="has('nationality')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select id="nationality" ref="nationality" v-model="user.nationality"
                                    class="ui fluid suidropdown" name="nationality"
                            >
                                <option :value="null">
                                    {{ $t('signup.nacionalidade') }}
                                </option>
                                <option v-for="(country, index) in nacionality" :key="index" :value="country.sigla"
                                        :data-value="country.sigla"
                                >
                                    {{ country.nome_pais_int }}
                                </option>
                            </select>
                        </div>
                        <div v-if="has('group_name')" class="field wide" :class="{ eight: !checkMobile, sixteen: checkMobile }">
                            <select id="group_name" v-model="user.group_name" class="ui suidropdown" name="group_name">
                                <option :value="null">
                                    {{ $t('signup.grupo') }}
                                </option>
                                <option v-for="(gn, index) in groups" :key="index" :value="gn.id">
                                    {{ gn.group_name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div v-show="tab==='password'" class="ui bottom attached tab segment" :class="{active:tab==='password'}">
                <form class="ui form">
                    <div class="fields">
                        <div v-if="change_password_social" class="field sixteen wide">
                            <input v-model="password.current" type="password" name="current" :placeholder="$t('profile.senhaAtual')">
                            <p class="small red" v-if="password.errors.current_password">{{ password.errors.current_password }}</p>
                        </div>
                        <div class="field sixteen wide">
                            <input v-model="password.new" type="password" name="new" :placeholder="placeholderNewPass">
                            <p class="small red" v-if="password.errors.password">{{ password.errors.password }}</p>
                        </div>
                        <div class="field sixteen wide">
                            <input v-model="password.confirmation" type="password" name="confirmation"
                                   :placeholder="placeholderConfirmationPass">
                            <p class="small red" v-if="password.errors.password_confirmation">{{ password.errors.password_confirmation }}</p>
                        </div>
                    </div>
                </form>
            </div>
            <div v-show="tab==='account'" class="ui bottom attached tab segment" :class="{active:tab==='account'}">
                <form class="ui form">
                    <div class="header-account">
                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9983 11.728C21.9719 11.9182 21.9513 12.1095 21.9187 12.3008C21.8757 12.5661 21.7342 12.8054 21.5224 12.9709C21.3106 13.1363 21.0442 13.2157 20.7764 13.1931C20.5086 13.1705 20.2592 13.0477 20.0781 12.8491C19.897 12.6505 19.7975 12.3909 19.7996 12.1221C19.8036 11.7784 19.8471 11.4348 19.8494 11.0911C19.8764 6.83347 16.9555 3.21454 12.7924 2.33759C7.99586 1.32774 3.25202 4.49417 2.31677 9.32972C1.36433 14.2518 4.71474 19.0226 9.64758 19.7546C11.7945 20.072 13.799 19.6641 15.6611 18.5311C15.9057 18.3822 16.1594 18.2774 16.4515 18.3186C16.6731 18.3453 16.8807 18.4408 17.0451 18.5918C17.2096 18.7427 17.3224 18.9415 17.3678 19.1601C17.4664 19.6137 17.2865 20.0445 16.8764 20.3057C15.9601 20.8865 14.9779 21.3407 13.9229 21.5996C13.2592 21.7629 12.5748 21.8448 11.9001 21.9639C11.8428 21.9737 11.7896 21.988 11.7346 22H10.2289C10.1755 21.9834 10.1209 21.9705 10.0657 21.9616C8.29718 21.817 6.59166 21.2384 5.10018 20.277C3.21649 19.0803 1.74144 17.3387 0.870995 15.2836C0.000548428 13.2285 -0.224094 10.9571 0.226912 8.77125C1.1026 4.4535 4.56011 1.03619 8.89617 0.211362C9.3658 0.122006 9.84173 0.0693085 10.3148 0H11.7752C12.1555 0.0572797 12.5375 0.101385 12.9161 0.166684C17.4463 0.949698 21.0607 4.5652 21.8282 9.0817C21.8946 9.47521 21.9427 9.87216 22 10.2674L21.9983 11.728Z" fill="#F2E253"/>
                            <path d="M9.90039 6.95201H11.7136V12.3493H9.90039V6.95201ZM10.1534 14.795C9.98472 14.6123 9.90039 14.3944 9.90039 14.1414C9.90039 13.8884 9.98472 13.6776 10.1534 13.5089C10.3361 13.3403 10.554 13.2559 10.807 13.2559C11.06 13.2559 11.2708 13.3403 11.4395 13.5089C11.6222 13.6776 11.7136 13.8884 11.7136 14.1414C11.7136 14.3944 11.6222 14.6123 11.4395 14.795C11.2708 14.9637 11.06 15.048 10.807 15.048C10.554 15.048 10.3361 14.9637 10.1534 14.795Z" fill="#F2E253"/>
                        </svg>
                        <p>
                            <strong>{{$t('profile.alert')}}</strong>
                            {{$t('profile.alertDelete')}}
                        </p>
                    </div>
                    <div class="fields">
                        <input type="text" name="email" style="display: none">
                        <input type="password" name="password" style="display: none">
                        <div class="field sixteen wide">
                            <input v-model="userDelete.email" type="email" :placeholder="$t('signup.meuEmail')" autocomplete="off">
                        </div>
                        <div class="field sixteen wide">
                            <input v-model="userDelete.password" type="password" :placeholder="$t('profile.senha')" autocomplete="off">
                        </div>
                        <div v-if="captchaClient" class="field sixteen wide">
                            <vue-recaptcha
                                    class="g-recaptcha"
                                    ref="recaptcha" :sitekey="captchaClient"
                                    @render="checkCaptcha = false" @verify="verifyCaptcha"
                                    @expired="expireCaptcha"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                {{$t('profile.cancelar')}}
            </div>
            <div class="ui positive button" :class="{red:tab==='account', disabled:disabledDelete}">
                {{ tab==='account'?$t('profile.deletarConta'):$t('profile.confirm')}}
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import countries from "../../../../../public/js/countries";
import {VueRecaptcha} from "vue-recaptcha";

const emitter = require('tiny-emitter/instance')
export default {
    name: 'ChangeUserDataModal',
    props: {
        userInformation: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            tab: 'personal',
            states: [
                { id: 1, value: "Acre", capital: "Rio Branco" }, { id: 2, value: "Alagoas", capital: "Maceió" }, { id: 3, value: "Amapá", capital: "Macapá" },
                { id: 4, value: "Amazonas", capital: "Manaus" }, { id: 5, value: "Bahia", capital: "Salvador" }, { id: 6, value: "Ceará", capital: "Fortaleza" },
                { id: 7, value: "Distrito Federal", capital: "Brasília" }, { id: 8, value: "Espírito Santo", capital: "Vitória" },
                { id: 9, value: "Goiás", capital: "Goiânia" }, { id: 10, value: "Maranhão", capital: "São Luís" },
                { id: 11, value: "Mato Grosso", capital: "Cuiabá" }, { id: 12, value: "Mato Grosso do Sul", capital: "Campo Grande" },
                { id: 13, value: "Minas Gerais", capital: "Belo Horizonte" }, { id: 14, value: "Pará", capital: "Belém" },
                { id: 15, value: "Paraíba", capital: "João Pessoa" }, { id: 16, value: "Paraná", capital: "Curitiba" },
                { id: 17, value: "Pernambuco", capital: "Recife" }, { id: 18, value: "Piauí", capital: "Teresina" },
                { id: 19, value: "Rio de Janeiro", capital: "Rio de Janeiro" },
                { id: 20, value: "Rio Grande do Norte", capital: "Natal" }, { id: 21, value: "Rio Grande do Sul", capital: "Porto Alegre" },
                { id: 22, value: "Rondônia", capital: "Porto Velho" }, { id: 23, value: "Roraima", capital: "Boa Vista" },
                { id: 24, value: "Santa Catarina", capital: "Florianópolis" }, { id: 25, value: "São Paulo", capital: "São Paulo" },
                { id: 26, value: "Sergipe", capital: "Aracaju" }, { id: 27, value: "Tocantins", capital: "Palmas" },
            ],
            cities: [],
            nacionality: countries,
            user: {
                name: this.userInformation.profile.name,
                nickname: this.userInformation.profile.nickname,
                email: this.userInformation.profile.email,
                phone: this.userInformation.profile.phone,
                cpf: this.userInformation.profile.cpf,
                birth: this.userInformation.profile.birth,
                company: this.userInformation.profile.company,
                state: this.userInformation.profile.state,
                city: this.userInformation.profile.city,
                region: this.userInformation.profile.region,
                nationality: this.userInformation.profile.nationality,
                group_name: this.userInformation.profile.group_name,
                enrollment: this.userInformation.profile.enrollment,
            },
            password: {
                current: '',
                new: '',
                confirmation: '',
                errors: {
                    password: '',
                    password_confirmation: '',
                    current_password: '',
                }
            },
            userDelete: {
                email: '',
                password: '',
                "g-recaptcha-response": ''
            },
            checkCaptcha: false,
        }
    },
    computed: {
        ...mapGetters([
            "checkMobile","token","change_password_social","switch"
        ]),
        regions() {
            return this.userInformation.edit.region;
        },
        groups() {
            return this.userInformation.edit.group_name;
        },
        placeholderNewPass(){
            return this.change_password_social ? this.$i18n.t('profile.novaSenha') : this.$i18n.t('profile.senha');
        },
        placeholderConfirmationPass(){
            return this.change_password_social ? this.$i18n.t('profile.repetirNovaSenha') : this.$i18n.t('profile.repetirSenha');
        },
        disabledDelete(){
            return this.tab === 'account' && ((!this.userDelete.email || !this.userDelete.password) || (this.captchaClient && !this.checkCaptcha));
        },
        captchaClient(){
            return this.switch.captcha_client;
        },
        only_major() {
            return this.switch.only_major ?? false;
        }
    },
    components: {
        VueRecaptcha
    },
    mounted() {
        this.avatarOption = this.userInformation.profile.use_avatar ? 'metaverse' : 'avatar';
        window.$(this.$el).suimodal({
            closable: false,
            onApprove: () => {
                this.confirmSelection()
                return false;
            },
            onDeny: () => {
                // Implementar a lógica de cancelamento
            },
            onHidden: () => {
                this.$emit('close');
            }
        }).suimodal('show');
        this.cityHandler();
        window.$(this.$el).find('.ui.calendar').calendar({
            type: 'date',
            formatter: {
                date: 'DD/MM/YYYY',
            },
            monthFirst: false,
            onChange: (date, text) => {
                this.user.birth = text;
            }
        })
    },
    beforeDestroy() {
        window.$(this.$el).remove();
    },
    methods: {
        confirmSelection() {
            if (this.tab === 'personal') {
                this.updatePersonalData();
            } else if (this.tab === 'password') {
                this.updatePassword();
            } else if (this.tab === 'account') {
                this.deleteAccount();
            }
        },
        updatePersonalData() {
            let data = {
                name: this.user.name,
                nickname: this.user.nickname,
                email: this.user.email,
            }
            let fields = ["phone", "cpf", "birth", "company", "state", "region", "nationality", "group_name", "enrollment",]
            fields.forEach(field => {
                if (this.has(field)) {
                    switch (field) {
                        case "birth":
                            data["birth"] = this.user.birth.split('/').reverse().join('-');
                            break;
                        case "region":
                            data["region_id"] = this.user.region;
                            break;
                        case "state":
                            data['state'] = this.user.state;
                            data['city']  = this.user.city;
                            break;
                        default:
                            data[field] = this.user[field];
                    }
                }
            });
            emitter.emit('update::user::profile', data);
        },
        updatePassword() {
            let data = {
                password: this.password.new,
                password_confirmation: this.password.confirmation
            }
            if (this.change_password_social) {
                data['current_password'] = this.password.current;
            }
            axios.post(this.$url_api + "api/user/changePassword", data, {headers: this.$app.headers,}).then((response) => {
                response.data;
                window.$.toast({
                    class: 'success',
                    position: 'top right',
                    title: this.$t('notification.sucesso'),
                    message: this.$t('profile.suaSenhaFoi'),
                    showProgress: 'bottom',
                    displayTime: 6000,
                    showIcon: 'times',
                });
                this.close();
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors.password) {
                        this.password.errors.password = errors.password[0];
                    }
                    if (errors.password_confirmation) {
                        this.password.errors.password_confirmation = errors.password_confirmation[0];
                    }
                    if (errors.current_password) {
                        this.password.errors.current_password = errors.current_password[0];
                    }
                }
                window.$.toast({
                    class: 'error',
                    position: 'top right',
                    title: this.$t('login.erro'),
                    message: this.$t('login.ocorreuUmErro'),
                    showProgress: 'bottom',
                    displayTime: 6000,
                    showIcon: 'times',
                });
            });
        },
        deleteAccount(){
            axios.delete(this.$url_api + "api/user", {headers: this.$app.headers, data: this.userDelete}).then((response) => {
                response.data;
                window.$.toast({
                    class: 'success',
                    position: 'top right',
                    title: this.$t('notification.sucesso'),
                    message: this.$t('profile.suaContaFoi'),
                    showProgress: 'bottom',
                    displayTime: 6000,
                    showIcon: 'times',
                });
                this.$app.update_checkers(0);
                this.$store.dispatch("signup/reset")
                this.$app.logoutEnd();
                this.close();
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 422) {
                    let errors = error.response.data.errors;
                    let errorMsg;
                    if (errors.email) {
                        errorMsg = errors.email[0];
                    }else if(errors.password){
                        errorMsg = errors.password[0];
                    }
                    window.$.toast({
                        class: 'error',
                        position: 'top right',
                        title: this.$t('login.erro'),
                        message: errorMsg,
                        showProgress: 'bottom',
                        displayTime: 6000,
                        showIcon: 'times',
                    });
                }else if(error.response.status && error.response.data.error){
                    window.$.toast({
                        class: 'error',
                        position: 'top right',
                        title: this.$t('login.erro'),
                        message: error.response.data.error,
                        showProgress: 'bottom',
                        displayTime: 6000,
                        showIcon: 'times',
                    });
                }else{
                    window.$.toast({
                        class: 'error',
                        position: 'top right',
                        title: this.$t('login.erro'),
                        message: this.$t('login.ocorreuUmErro'),
                        showProgress: 'bottom',
                        displayTime: 6000,
                        showIcon: 'times',
                    });
                }
            });
        },
        close() {
            window.$(this.$el).suimodal('hide');
        },
        just_number(v) {
            return v.replace(/\D/g, '');
        },
        check(e) {
            if ((e.key !== "Backspace") || (e.key !== "ArrowLeft") || (e.key !== "ArrowRight") || (e.key !== "Tab") || (e.key !== "Delete")) {
                if (!/^[0-9]*$/.test(e.key)) {
                    e.preventDefault();
                    return false;
                }
            }
        },
        mask_phone(e, value) {
            if (e) {
                this.check(e);
                let x = this.just_number(e.currentTarget.value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                e.currentTarget.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else if (value) {
                let x = this.just_number(value).match(/(\d{0,2})(\d{0,5})(\d{0,5})/);
                return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '' + x[3] : '');
            }
            else {
                return null;
            }
        },
        mask_cpf(e, value) {
            if (e) {
                this.check(e);
                let v = this.just_number(e.currentTarget.value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                e.currentTarget.value = !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
            }
            else if (value) {
                let v = this.just_number(value).match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/);
                return !v[2] ? v[1] : v[1] + '.' + v[2] + (v[3] ? '.' + v[3] : '') + (v[4] ? '-' + v[4] : '');
            }
            else {
                return null;
            }
        },
        cityHandler(e) {
            let uf;
            if (e) {
                uf = Number(e.currentTarget[e.currentTarget.selectedIndex].getAttribute('data-value'));
            } else {
                let state = this.states.find(_ => _.value === this.user.state);
                if (state) {
                    uf = state.id;
                }else {
                    return;
                }
            }
            axios.post(this.$url_api + 'api/store/get_cities', {uf}, { headers: this.headers })
                .then((response) => {
                    let data = response.data;
                    let capital = this.states.find(_ => _.id === uf).capital;
                    const capitalArray = data.filter(_ => _.name === capital)
                    data = data.filter(_ => _.name !== capital)
                    this.cities = [...capitalArray, ...data];
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        has(field) {
            return this.userInformation.edit[field];
        },
        verifyCaptcha(response){
            this.userDelete["g-recaptcha-response"] = response;
            this.checkCaptcha = true;
        },
        expireCaptcha(){
            this.checkCaptcha = false;
        }
    }
}
</script>
<style scoped lang="scss">
.suimodal {
  padding: 54px 44px;

  .icon.close {
    top: 44px;
    right: 44px;
    color: #fff;
  }

  .ui.deny.button {
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 12px;
  }
  .fields {
    flex-wrap: wrap;
    margin-bottom: 0;

    .field.wide {
      padding: 0.5em;
      input{
        padding: 12px 24px;
        border-radius: 10px;
      }
      .small{
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        margin-top: 5px;
        &.red{
            color: #ff0000;
        }
      }
    }
  }
  .ui.tabular.menu{
    background-color: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6);
    .item{
        font-size: 1rem;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.6);
        background-color: transparent;
        border: none;
        padding: 12px 24px;
        &.active{
            background-color: transparent;
            border:none;
            color: #fff;
            border-bottom: 2px solid #fff;
        }
    }
  }
  .ui.tab.segment.active{
    border: none;
    background-color: transparent;
    padding: 0;
    margin-top: 1rem;
  }
  .ui.positive.button{
    background-color: var(--buttons_color);
    color: var(--fonts_color);
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 700;
    &.red {
      background-color: #db2828;
      color: #fff;
    }
    &.disabled{
      background-color: #ccc;
      color: #fff;
      pointer-events: none;
    }
  }
  .header-account{
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
    svg{
      min-width: 46px;
      margin-right: .5rem;
    }
    p{
      font-family: Exo, sans-serif;
      font-size: 1.25rem;
      font-weight: 400;
      color: #fff;
      line-height: 1.5;
      text-align: left;
    }
  }
  .g-recaptcha{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 767.98px) {
  .ui.overlay.fullscreen.suimodal > .content.content.content {
    min-height: calc(100vh - 20rem);
  }
}
</style>